/*
 * tipos de acciones
 */

export const SET_USER_INFO = 'SET_USER_INFO'

export function setUserInfo (userInfo) {
  console.log(userInfo)
  return { type: SET_USER_INFO, userInfo }
}
