// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth, EmailAuthProvider, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth'

import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import { setUserInfo } from '../../redux/actions'
import store from '../../redux/store'
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAHTfhLGA3WMiSdF_HCVqqMRTLFNTLC4Kw',
  authDomain: 'jamaica-orders.firebaseapp.com',
  projectId: 'jamaica-orders',
  storageBucket: 'jamaica-orders.appspot.com',
  messagingSenderId: '789113611355',
  appId: '1:789113611355:web:591c729f31132f41248b7e',
  measurementId: 'G-M8DPKP18S4'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// const db = getFirestore(app);
// const analytics = getAnalytics(app);

export const authentication = getAuth(app)
authentication.useDeviceLanguage()

const ui = new firebaseui.auth.AuthUI(authentication)

const authUiConfig = {
  signInSuccessUrl: '<url-to-redirect-to-on-success>',
  signInFlow: 'popup',
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true
    },
    uiShown: function () {
      // The widget is rendered.
      // Hide the loader.
      // document.getElementById('loader').style.display = 'none'
    }
  },
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID
  ],

  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: `${window.location.origin}/terms`,
  // Privacy policy url/callback.
  privacyPolicyUrl: function () {
    window.location.assign(`${window.location.origin}/privacy`)
  }
}
ui.start('#firebaseui-auth-container', authUiConfig)

onAuthStateChanged(authentication, (user) => {
  if (user) {
    store.dispatch(setUserInfo(user))
  } else {
    // User is signed out
    // ...
  }
})
