import { SET_USER_INFO } from './actions'
import update from 'immutability-helper'

const defaultUserState = {}

export function ordersReducer (state = {}, action) {
  switch (action.type) {
    default:
      return state
  }
}

export function usersReducer (state = defaultUserState, action) {
  switch (action.type) {
    case SET_USER_INFO:
      return update(state, { $set: action.userInfo })
    default:
      return state
  }
}
