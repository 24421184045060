import logo from './imgs/logo.jpg'
import './components/firebase/config'
import React from 'react'
import { Box, Container, Avatar, Grid } from '@mui/material'

function App () {
  return (
    <Container maxWidth="sm">
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >

        <Grid item xs={3}>
          <Avatar
            alt="Remy Sharp"
            src={logo}
            sx={{ width: 250, height: 250 }}
          />
        </Grid>
        <Box id="firebaseui-auth-container"/>
    </Grid>
    </Container>
  )
}

export default App
