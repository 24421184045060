import { configureStore } from '@reduxjs/toolkit'
import { ordersReducer, usersReducer } from './reducers'

const store = configureStore({
  reducer: {
    users: usersReducer,
    orders: ordersReducer
  }
})

export default store
